const ERole = Object.freeze({
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_USER: "ROLE_USER",
    ROLE_USER_1: "ROLE_USER_1",
    ROLE_USER_2: "ROLE_USER_2",

    ROLE_SISWA: "ROLE_SISWA",
    ROLE_GURU: "ROLE_GURU"


})
export default ERole
<template>
<!--  <v-footer class="pa-3 color-gradient-1" height="auto" inset app>-->
  <v-footer class="pa-3 color-gradient-1" height="auto">
    <v-spacer></v-spacer>
    <v-flex text-xs-center white--text xs12>
      &copy; {{ new Date().getFullYear() }} — SIAPP (Sistem Informasi aplikasi Pondok Pesantren)
<!--      <v-btn flat small color="white" to="" class="pa-0 ma-0"></v-btn>-->
    </v-flex>
  </v-footer>
</template>
<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
</style>

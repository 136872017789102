import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtKrsService {
    getApiUrl() {
        return API_URL
    }
    getAllFtKrs(){
        return axios.get(API_URL + `getAllFtKrs`, { headers: authHeader() });
    }
    getAllFtKrsContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFtKrsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFtKrsById(id){
        return axios.get(API_URL + `getFtKrsById/${id}`, { headers: authHeader() });
    }
    getAllFtKrsByParentPublic(fsiswaBean, fkurikulumBean){
        return axios.get(API_URL + `public/getAllFtKrsByParent/${fsiswaBean}/${fkurikulumBean}`, { headers: authHeader() });
    }
    getAllFtKrsByFkurikulumsPublic(fkurikulums){
        return axios.get(API_URL + `public/getAllFtKrsByFkurikulums/${fkurikulums}`, { headers: authHeader() });
    }
    getAllFtKrsByFkurikulumId(id){
        return axios.get(API_URL + `public/getAllFtKrsByFkurikulumId/${id}`, { headers: authHeader() });
    }



    updateFtKrs(item){
        return axios.put(API_URL + `updateFtKrs/${item.id}`, item, {headers: authHeader()})
    }
    updateFtKrsPublic(item){
        return axios.put(API_URL + `public/updateFtKrs/${item.id}`, item, {headers: authHeader()})
    }
    createFtKrs(item){
        return axios.post(API_URL + `createFtKrs`, item, {headers: authHeader()})
    }
    createFtKrsPublic(item){
        return axios.post(API_URL + `public/createFtKrs`, item, {headers: authHeader()})
    }
    deleteFtKrs(id){
        return axios.delete(API_URL + `deleteFtKrs/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFtKrs(itemIds){
        return axios.delete(API_URL + `deleteAllFtKrs`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtKrsService()
import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FKurikulumService {
    getApiUrl() {
        return API_URL
    }

    getAllFKurikulumContainingActive(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `public/getAllFKurikulumContainingActive?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { });
    }
    getAllFKurikulumContainingPublic(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `public/getAllFKurikulumContainingPublic?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { });
    }


    getAllFKurikulum(){
        return axios.get(API_URL + `getAllFKurikulum`, { headers: authHeader() });
    }
    getAllFKurikulumContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFKurikulum`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFKurikulumContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }

    getFKurikulumById(id){
        return axios.get(API_URL + `getFKurikulumById/${id}`, { headers: authHeader() });
    }
    updateFKurikulum(item){
        return axios.put(API_URL + `updateFKurikulum/${item.id}`, item, {headers: authHeader()})
    }
    createFKurikulum(item){
        return axios.post(API_URL + `createFKurikulum`, item, {headers: authHeader()})
    }
    deleteFKurikulum(id){
        return axios.delete(API_URL + `deleteFKurikulum/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFKurikulum(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFKurikulum`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFKurikulum`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FKurikulumService()
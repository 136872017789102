<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <PpdbMarket></PpdbMarket>
      </v-col>
    </v-row>
    <AppFooter/>
  </div>
</template>

<script>
import AppFooter from "@/components/landing/AppFooter";
import PpdbMarket from "@/components/ppdb/market/PpdbMarket";
export default {
  components: {PpdbMarket, AppFooter},
  data () {
    return {
    }
  },


}
</script>

<style>
</style>

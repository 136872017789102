const ConstApiUrls = Object.freeze({

    // AUTH_SERVICE_URL: 'http://localhost:8383/api/auth/',
    // TEST_SERVICE_URL: 'http://localhost:8383/api/test/',
    // API_SERVICE_URL: 'http://localhost:8383/api/siapp/'


    // AUTH_SERVICE_URL: 'https://des-green.com:8383/api/auth/',
    // TEST_SERVICE_URL: 'https://des-green.com:8383/api/test/',
    // API_SERVICE_URL: 'https://des-green.com:8383/api/siapp/'

    AUTH_SERVICE_URL: 'https://rep-4.npm.des-green.com/api/auth/',
    TEST_SERVICE_URL: 'https://rep-4.npm.des-green.com/api/test/',
    API_SERVICE_URL: 'https://rep-4.npm.des-green.com/api/siapp/'

})
export default ConstApiUrls
<template>

  <v-app id="app" class="grey lighten-4">

    <NavDrawer v-if="currentUser!==null"/>
    <MenuBar  v-if="currentUser===null"/>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from './components/NavDrawer'
import MenuBar from "@/components/MenuBar";
import TestService from "@/services/test-service";

export default {
  components: {MenuBar: MenuBar, NavDrawer},
  name: 'App',
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },

  },
  mounted() {
    TestService.getAdminBoard().then(
        ()=>{
        },
        error=>{
          console.log(`${error.response.status}`)
          if (error.response.status===401){
            if (this.currentUser !== undefined || this.currentUser !== ''){
              this.$store.dispatch('auth/logout');
              // this.$router.push('/login'); //Tidak pelu
            }

          }
        }

    )



  }

}

</script>


<style>
.color-gradient-1 {
  /*background-image: linear-gradient(to top left, #f384ac 10%, transparent 90%);*/
  background-image: linear-gradient(to top left, #27c53c 10%, transparent 90%);
}
.bottom-gradient {
  background-image: linear-gradient(to top right, #2bd771 10%, transparent 90%);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(-45deg,
  rgba(255,0,0,.25),
  rgba(255,0,0,.25) 5px,
  rgba(0,0,255,.25) 5px,
  rgba(134, 134, 234, 0.25) 10px
  );
}
.navbar_submenu_color{
  background-color: #a0f6c0;
}


</style>


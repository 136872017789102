<template>
  <nav>
    <v-toolbar :flat="currentUser===null">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text" v-if="currentUser"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text" >
          <span class="font-weight-light">SIAPP</span>
          <span style="width: 1000px;">PPDI</span>
      </v-toolbar-title>
<!--      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey&#45;&#45;text" v-if="currentUser && drawer"></v-app-bar-nav-icon>-->
      <v-spacer></v-spacer>


<!--      <v-toolbar-items>-->
<!--        <v-btn plain v-for="item in top_menus" :key="item.title" :to="item.path">{{ item.title }}</v-btn>-->
<!--      </v-toolbar-items>-->

      <v-menu offset-y v-if="currentUser">
        <template v-slot:activator="{ on, attrs }" >
          <v-btn
            plain
            v-bind="attrs"
            v-on="on"
          >
            <span small>{{ currentUser.username }} </span>
            <v-avatar color="grey lighten-1" size="36">
              <v-img
                :lazy-src="lookupImageUrlLazy(currentUser.avatarImage)"
                :src="lookupImageUrl(currentUser)"
              ></v-img>
            </v-avatar>
          </v-btn>

        </template>
        <v-list color="grey lighten-4" >
          <v-list-item
            v-for="link in top_menus" :key="link.title" route :to="link.route"
          >
            <span class="align-center align-content-center">{{link.title}}</span>
          </v-list-item>
          <v-list-item>
            <v-btn plain elevation="0" color="gray darken-1" @click="logOut" small>
              Keluar
              <v-icon color="red">mdi-power</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

      </v-menu>

    </v-toolbar>


    <v-navigation-drawer app  class="bottom-gradient"  v-model="drawer"  v-if="showNavbar">
     <v-layout column align-center>
        <v-flex class="mt-5">
            <v-img
              lazy-src="../assets/images/logo_dahlanikhsan.png"
              max-height="150"
              max-width="150"
              src="../assets/images/logo_dahlanikhsan.png"
            ></v-img>
        </v-flex>
        <v-flex class="mt-4 mb-3">
        </v-flex>
      </v-layout>

     <v-divider></v-divider>

      <v-list>

        <v-list-item route to='/dashboard' class="body-2" link>
          <v-list-item-icon>
            <v-icon>mdi-rocket</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="nav_drawer in nav_drawers.filter(x=>x.visible)"
          :key="nav_drawer.title"
          :prepend-icon="nav_drawer.icon"
          no-action
          small
          v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title"> </v-list-item-title>
            </v-list-item-content>
          </template>

              <v-list-item
                v-for="submenu in nav_drawer.items.filter(x => x.visible)"
                :key="submenu.title"
                link
                router :to="submenu.route"  class="navbar_submenu_color" >

                <template>
                  <v-list-item-content>
                    <v-list-item-title class="body-2" v-text="submenu.title" > </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small >{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
        </v-list-group>
      </v-list>

    </v-navigation-drawer>

 
  </nav>
</template>

<script>
import ERole from "@/models/e-role";
import FileService from "@/services/apiservices/file-service"
import TestService from "../services/test-service"

export default {
  components: {  },
  data() {
    return {

      top_menus: [
        { icon: 'mdi-help-box', title: 'Ubah Profile', route: '/change-profile', visible: true  },
      ],

      drawer: true,
    }
  },
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      // console.log(JSON.stringify(this.$store.state.auth.user))

      return this.$store.state.auth.user;
    },
    showNavbar() {
      if (this.currentUser && this.currentUser.roles) {
        // return this.currentUser.roles.includes(
        //     ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
        //     ERole.ROLE_USER_2,  ERole.ROLE_USER_2,  ERole.ROLE_USER_2,
        //     ERole.ROLE_PENGAWASAN,  ERole.ROLE_PHO_FHO_1,  ERole.ROLE_FISIK_1, ERole.ROLE_PENYERAHAN_1,
        //     ERole.ROLE_KEUANGAN,  ERole.ROLE_KEUANGAN_1,
        //     ERole.ROLE_PUBLIC,  ERole.ROLE_PUBLIC_1
        // )
        return true
      }
      return false;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes(ERole.ROLE_ADMIN);
      }
      return false;
    },

    nav_drawers() {
      return [
        {
          title: 'Pendaftaran',
          icon: 'mdi-walk',
          active: true,
          visible:
              [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some( x=>this.currentUser.roles.includes(x))
          ,
          items: [
            {title: 'PPDB/KRS', route: '/ppdb-krs', icon: 'mdi-walk', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Validasi PPDB/KRS', route: 'ppdb-validasi-krs', icon: 'mdi-checkbox-marked-circle', visible:
                [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Informasi Penting', route: '/informasi-penting', icon: 'mdi-information-variant', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
          ],
        },
        {
          title: 'Pembayaran & Biaya',
          icon: 'mdi-credit-card',
          active: false,
          visible:
              [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some( x=>this.currentUser.roles.includes(x))
          ,
          items: [
            {title: 'Biaya & Pembayaran', route: 'biaya-pembayaran', icon: 'mdi-credit-card', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Komponen Biaya', route: '/komponen-biaya', icon: 'mdi-cog', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
          ],
        },
        {
          title: 'Setting Akademis',
          icon: 'mdi-school',
          active: false,
          visible:
              [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some( x=>this.currentUser.roles.includes(x))
          ,
          items: [
            {title: 'Mata Pelajaran', route: '/mata-pelajaran', icon: 'mdi-library-books', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Periode Belajar', route: '/periode-belajar', icon: 'mdi-calendar-clock', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Kurikulum', route: '/kurikulum', icon: 'mdi-calendar-check', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Siswa/Santri', route: '/siswa-santri', icon: 'mdi-account-multiple-outline', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
          ],
        },
        {
          title: 'Donasi & Penggunaan',
          icon: 'mdi-diamond',
          active: false,
          visible:
              [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some( x=>this.currentUser.roles.includes(x))
          ,
          items: [
            {title: 'Biaya & Pembayaran', route: 'biaya-pembayaran', icon: 'mdi-credit-card', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
            {title: 'Komponen Biaya', route: '/komponen-biaya', icon: 'mdi-settings', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1].some(x => this.currentUser.roles.includes(x))
            },
          ],
        },

        {
          title: 'Reports',
          icon: 'mdi-chart-line',
          active: false,
          visible:
              [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
              ERole.ROLE_USER_2,  ERole.ROLE_USER_2,  ERole.ROLE_USER_2,
              ].some( x => this.currentUser.roles.includes(x))
           ,
          items: [
            {title: 'Laporan', route: '/progres_kegiatan-laporan', icon: '',
              visible:  [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
                  ERole.ROLE_USER_2,  ERole.ROLE_USER_2,  ERole.ROLE_USER_2 ].some(x =>this.currentUser.roles.includes(x))
               },
          ],
        },
        {
          title: 'Halaman Public',
          icon: 'mdi-rss',
          active: false,
          visible:
              [ERole.ROLE_ADMIN ].some(x => this.currentUser.roles.includes(x))
           ,
          items: [
            {title: 'Berita-Agenda', route: '/public-berita-agenda', icon: 'mdi-rss',
              visible: [ERole.ROLE_ADMIN].some(x =>this.currentUser.roles.includes(x))
            },
            {title: 'Informasi-Teknis', route: '/public-informasi-teknis', icon: '',
              visible: [ERole.ROLE_ADMIN].some(x =>this.currentUser.roles.includes(x))
            },
          ],
        },
        {
          title: 'Admin',
          icon: 'mdi-cog-outline',
          active: false,
          visible:  [ERole.ROLE_ADMIN, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Sistem', route: '/admin-sistem', icon: 'mdi-account', visible:  [ERole.ROLE_ADMIN].some(x =>this.currentUser.roles.includes(x))  },
            {title: 'Struktur Organisasi', route: '/admin-struktur-organisasi', icon: 'mdi-file-tree', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Jabatan', route: '/admin-jabatan', icon: 'mdi-account-star', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },


      ]
    },

  },

  methods: {

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    // signOut() {
    //     localStorage.setItem("authenticated", false)
    //     this.$store.commit("setAuthenticatedFalse")
    //     this.$router.push( { name: "Login"})
    // },

    lookupImageUrl(item){

      // console.log(JSON.stringify(item))

      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }

    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

  },

  mounted() {
      TestService.getAdminBoard().then(
          ()=>{
          },
          error=>{
            console.log(`${error.response.status}`)
            if (error.response.status===401){
              if (this.currentUser !== undefined || this.currentUser !== ''){
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }

            }
          }
      )

  }

}
</script>


<style scoped>

</style>
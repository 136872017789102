<template>
  <nav >
    <v-toolbar class="color-gradient-1">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text hidden-md-and-up" ></v-app-bar-nav-icon>
      <v-flex class="ml-2">
          <v-img
              lazy-src="../assets/images/logo_dahlanikhsan.png"
              height="40"
              width="40"
              src="../assets/images/logo_dahlanikhsan.png"
          ></v-img>

      </v-flex>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn plain elevation="0" color="gray darken-1" route to="/home" v-if="currentUser===null">
          <span>Home</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn plain elevation="0" color="gray darken-1" route to="/landing-berita-agenda" v-if="currentUser===null">
          <span>Berita-Agenda</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn plain elevation="0" color="gray darken-1" route to="/pendaftaran" v-if="currentUser===null">
          <v-icon color="blue" small>mdi-walk</v-icon>
          <span class="blue--text">Pendaftaran</span>
        </v-btn>
      </v-toolbar-items>


      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                plain
                color="red darken-1"
                route to="/login"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </template>
          <span>Login</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer app  class="bottom-gradient hidden-sm-and-up"  v-model="drawer">
      <v-layout column align-center class="mb-4">
        <v-flex class="mt-5">
          <v-img
              lazy-src="../assets/images/logo_dahlanikhsan.png"
              max-height="150"
              max-width="150"
              src="../assets/images/logo_dahlanikhsan.png"
          ></v-img>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>
      <v-list>
          <v-list-item route to='/home'>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        <v-list-item route to='/landing-berita-agenda'>
          <v-list-item-icon>
            <v-icon>mdi-rss</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Berita-Agenda</v-list-item-title>
        </v-list-item>
        <v-list-item route to='/ppdb-market'>
          <v-list-item-icon>
            <v-icon>mdi-walk</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pendaftaran</v-list-item-title>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>


  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,

      menu_berita_agenda: [
        { icon: '', title: 'Berita', route: '/public_berita', visible: true  },
        { icon: '', title: 'Agenda', route: '/public_agenda', visible: true  },
      ],
      menu_informasi: [
        { icon: '', title: 'Tenaga Kerja', route: '/public_tenaga_kerja', visible: true  },
        { icon: '', title: 'Kontraktor', route: '/public_cv_pt', visible: true  },
      ],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }

  },
  methods: {
    signIn() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
}
</script>


<style scoped>

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/landing/PpdbMarket'
import Landing from "@/views/landing/PpdbMarket";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/login',
    name: 'Login',
    component: () =>
        import ('../views/Login.vue')
  },
  {
    path: '/pendaftaran',
    name: 'HomeSmaView',
    component: () =>
        import ('../views/HomeSmaView.vue')
  },

  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
        import ('../views/Register.vue')
  },
  {
    path: '/change-profile',
    name: 'ChangeProfile',
    component: () =>
        import ('../views/admin/ChangeProfile'),
  },
  {
    path: '/admin-sistem',
    name: 'Setting',
    component: () =>
        import ('../views/admin/SettingAndUser.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
        import ('../views/Profile.vue')
  },
  {
    path: '/admin-struktur-organisasi',
    name: 'StrukturOrganisasi',
    component: () =>
        import ('../views/admin/StrukturOrganisasi.vue')
  },
  {
    path: '/admin-jabatan',
    name: 'Jabatan',
    component: () =>
        import ('../views/admin/Jabatan.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Home
  },
  {
    path: '/public-berita-agenda',
    name: 'PublicBeritaAgenda',
    component: () =>import('../views/berita_agenda/BeritaAgendaView')
  },
  {
    path: '/landing-berita-agenda',
    name: 'LandingBeritaAgenda',
    component: () =>import('../views/landing/BeritaAgenda')
  },
  {
    path: '/public-news-page/:id',
    name: 'PublicNewsPage',
    component: () =>
        import ('../views/landing/PpdbMarketItem')
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing
  },

  {
    path: '/about',
    name: 'About',
    component: () =>
        import ('../views/About.vue'),

  },

  {
    path: '/kurikulum',
    name: 'Kurikulum',
    component: () =>
        import ('../views/akademis/Kurikulum')
  },
  {
    path: '/periode-belajar',
    name: 'Periode',
    component: () =>
        import ('../views/akademis/Periode')
  },
  {
    path: '/siswa-santri',
    name: 'Siswa',
    component: () =>
        import ('../views/akademis/Siswa')
  },

  {
    path: '/ppdb-market',
    name: 'PpdbMarket',
    component: () =>import('../views/landing/PpdbMarket')
  },
  {
    path: '/ppdb-market-item/:id',
    name: 'PpdbMarketItem',
    component: () =>import('../views/landing/PpdbMarketItem')
  },
  {
    path: '/ppdb-validasi-krs',
    name: 'PpdbValidasi',
    component: () =>import('../views/landing/PpdbValidasi')
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
